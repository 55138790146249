let configuration: Record<string, string> | null = null

export type ExplorerEnvConfigType = {
	name: string
	url: string
}

export type Address = `0x${string}`

interface configEnv {
	EXCHANGE_SUBGRAPH_ENDPOINT: string
	MASTERCHEF_SUBGRAPH_ENDPOINT: string
	POSITIVE_IMPACT_SUBGRAPH_ENDPOINT: string
	LOCKER_SUBGRAPH_ENDPOINT: string
	BLOCK_SUBGRAPH_ENPOINT: string
	VERITREE_ENDPOINT: string
	COIN_GECKO_ENDPOINT: string
	GOVERNANCE_GRAPH_ENDPOINT: string
	KYOTO_CHAIN_NAME: string
	RPC_ENDPOINT: string
	FACTORY_ADDRESS: Address
	ROUTER_ADDRESS: Address
	TOKEN_HELPER_ADDRESS: Address
	MASTERCHEF_ADDRESS: Address
	BLACKHOLE_WALLET: Address
	KSWAP_VAULT_ADDRESS: Address
	BOOST_CONTRACT_ADDRESS: Address
	POSITIVE_IMPACT_CONTRACT_ADDRESS: Address
	WKYOTO: Address
	KSWAP_TOKEN: Address
	DISCORD_LINK: string
	TWITTER_LINK: string
	MEDIUM_LINK: string
	GITBOOK_LINK: string
	COINMARKET_LINK: string
	YOUTUBE_LINK: string
	TELEGRAM_LINK: string
	EXPLORER_CONFIG: string
	EXPLORER_NAME: string
	WALLETCONNECT_CLOUD_ID: string
	VERITREE_VIEW_URL: string
	DECIMAL_DENOMINATOR: string
	SLIPPAGE_MULTIPLIER: string
	BLOCKS_PER_YEAR: string
	DISABLED_TOKENS: [string]
	GOVERNANCE_GRAPH_SPACE: string
	FEATURED_TOKENS: [string]
	LS_PROP_CUSTOM_TOKENS: string
	EXPLORER_URL: string
	CHAIN_ID: number
	API_URL: string
	DEFAULT_USD_TOKEN_NAME: string
	DEFAULT_USD_TOKEN_SYMBOL: string
	DEFAULT_USD_TOKEN_ADDRESS: Address
	DEFAULT_USD_TOKEN_DECIMALS: number
	DEFAULT_USD_TOKEN_LOGO_URI: string
	TREE_PRICE: number
}

type ConfigKey = keyof configEnv

export const setEnvConfig = (cfg: Record<string, string>) => {
	configuration = cfg
}

export const getEnvConfigValue = <T extends ConfigKey>(
	key: T
): configEnv[T] => {
	return (configuration?.[key] || '') as configEnv[T]
}
