import {
	RainbowKitProvider,
	connectorsForWallets,
	darkTheme
} from '@rainbow-me/rainbowkit'
import {
	injectedWallet,
	metaMaskWallet,
	rainbowWallet,
	trustWallet,
	walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets'
import { FC, PropsWithChildren, useMemo } from 'react'
import { WagmiConfig, configureChains, createClient } from 'wagmi'
import { kyoto, kyotoRainbow } from '~/config/networks'
import { getEnvConfigValue } from '~/utils/envConfig'
import { publicProvider } from '@wagmi/core/providers/public'

export const getWagmiSettings = () => {
	const { chains, provider } = configureChains(
		[kyoto()],
		[publicProvider()]
	)

	const WALLETCONNECT_CLOUD_ID = getEnvConfigValue('WALLETCONNECT_CLOUD_ID')

	const connectors = connectorsForWallets([
		{
			groupName: 'Popular',
			wallets: [
				injectedWallet({ chains: [kyoto()] }),
				metaMaskWallet({ chains, projectId: WALLETCONNECT_CLOUD_ID }),
				rainbowWallet({ chains, projectId: WALLETCONNECT_CLOUD_ID }),
				walletConnectWallet({ chains, projectId: WALLETCONNECT_CLOUD_ID }),
				trustWallet({ chains, projectId: WALLETCONNECT_CLOUD_ID })
			]
		}
	])

	const wagmiClient = createClient({
		autoConnect: true,
		connectors,
		provider
	})

	return wagmiClient
}

export const Web3Provider: FC<PropsWithChildren> = ({ children }) => {
	const wagmiClient = useMemo(() => getWagmiSettings(), [])

	return (
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider
				theme={darkTheme({
					fontStack: 'system'
				})}
				chains={[kyotoRainbow()]}
				initialChain={kyotoRainbow()}
				showRecentTransactions={true}
				modalSize="compact"
			>
				{children}
			</RainbowKitProvider>
		</WagmiConfig>
	)
}
