import axios from 'axios'
import { Getter, atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'
import { Pair } from '~/types/pair'
import { Token } from '~/types/tokens'
import { getEnvConfigValue } from '~/utils/envConfig'
import { customTokensAtom } from './user'

export const kswapToken: () => Token = () =>
	({
		address: getEnvConfigValue('KSWAP_TOKEN').toLowerCase(),
		id: getEnvConfigValue('KSWAP_TOKEN').toLowerCase(),
		chainId: getEnvConfigValue('CHAIN_ID'),
		decimals: 18,
		logoURI: '/kswapLogo.svg',
		name: 'KyotoSwap Token',
		symbol: 'KSWAP',
		derivedBNB: '0',
		derivedUSD: '0'
	} as const)

const bnbToken: () => Token = () =>
	({
		name: 'Kyoto Blockchain Native Token',
		symbol: 'KYOTO',
		address: 'KYOTO',
		id: 'KYOTO',
		chainId: getEnvConfigValue('CHAIN_ID'),
		decimals: 18,
		logoURI: '/kyotoLogo.png',
		derivedBNB: '0',
		derivedUSD: '0'
	} as const)

export const wbnbToken: () => Token = () =>
	({
		name: 'Wrapped KYOTO',
		symbol: 'WKYOTO',
		address: getEnvConfigValue('WKYOTO'),
		id: getEnvConfigValue('WKYOTO'),
		chainId: getEnvConfigValue('CHAIN_ID'),
		decimals: 18,
		logoURI: '/kyotoLogo.png',
		derivedBNB: '0',
		derivedUSD: '0'
	} as const)

export const defaultUsdToken: () => Token = () =>
	({
		name: getEnvConfigValue('DEFAULT_USD_TOKEN_NAME'),
		symbol: getEnvConfigValue('DEFAULT_USD_TOKEN_SYMBOL'),
		address: getEnvConfigValue('DEFAULT_USD_TOKEN_ADDRESS'),
		id: getEnvConfigValue('DEFAULT_USD_TOKEN_ADDRESS'),
		chainId: getEnvConfigValue('CHAIN_ID'),
		decimals: getEnvConfigValue('DEFAULT_USD_TOKEN_DECIMALS'),
		logoURI: getEnvConfigValue('DEFAULT_USD_TOKEN_LOGO_URI'),
		derivedBNB: '0',
		derivedUSD: '0'
	} as const)

export const fetchTokens = async (get: Getter) => {
	const customTokens = get(customTokensAtom)
	const { data } = await axios.post<Token[]>('/api/tokens', {
		customTokens
	})
	return data
}

export const [tokenListAtom] = atomsWithQuery((get) => {
	return {
		queryKey: ['tokenList_atom'],
		queryFn: () => fetchTokens(get),
		initialData: []
	}
})

export const allTokenListAtom = atom((get) => {
	return [bnbToken(), ...get(tokenListAtom)]
})

export const fetchPairs = async () => {
	const { data } = await axios.get<Pair[]>('/api/pairs')
	return data
}

export const [pairListAtom] = atomsWithQuery(() => {
	return {
		queryKey: ['pairList'],
		queryFn: fetchPairs,
		initialData: [],
		refetchInterval: 5_000
	}
})

export const pairsMapAtom = atom((get) => {
	const pairs = get(pairListAtom)
	const map = new Map<string, Pair>()
	pairs.forEach((v) => map.set(v.id, v))
	return map
})

export const kswapAtom = atom((get) => {
	const kswap = get(allTokenListAtom).filter(
		(v) => v.address.toLowerCase() === kswapToken().address.toLowerCase()
	)

	if (kswap.length === 0) return kswapToken()
	return kswap[0]
})
