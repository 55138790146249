import { useAtom } from 'jotai'
import {
	ChangeEvent,
	createContext,
	ReactNode,
	useContext,
	useMemo,
	useState
} from 'react'
import { RxCross2 } from 'react-icons/rx'
import TokenImport from '~/components/Modals/TokenImport'
import useGetTokenInfo from '~/hooks/useGetTokenInfo'
import { userBalancesAtom } from '~/stores/balances'
import { allTokenListAtom, kswapToken, defaultUsdToken } from '~/stores/tokens'
import { customTokensAtom } from '~/stores/user'
import { Token } from '~/types/tokens'
import { getEnvConfigValue } from '~/utils/envConfig'
import FeaturedTokens from '../components/Modals/FeaturedTokens'
import TokenRow from '../components/Modals/TokenRow'
import clsx from 'clsx'

interface SelectedTokensConfig {
	inputToken: Token
	outputToken: Token
	tokensMap: Map<string, Token>
	showInputTokenModal: () => void
	showOutputTokenModal: () => void
	flipTokens: () => void
}

const SelectedTokensContext = createContext<SelectedTokensConfig>({
	inputToken: defaultUsdToken(),
	outputToken: kswapToken(),
	tokensMap: new Map(),
	showInputTokenModal: () => {},
	showOutputTokenModal: () => {},
	flipTokens: () => {}
})

export const TokenSelectionModalProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const [tokens] = useAtom(allTokenListAtom)
	const [userBalanceMap] = useAtom(userBalancesAtom)
	const [modalOpened, setModalOpened] = useState<
		'input' | 'output' | undefined
	>(undefined)
	const [inputToken, setInputToken] = useState(defaultUsdToken())
	const [outputToken, setOutputToken] = useState(kswapToken())

	const [searchTerm, setSearchTerm] = useState('')
	const [customTokens, setCustomTokens] = useAtom(customTokensAtom)

	const tokensMap = useMemo(() => {
		const map = new Map<string, Token>()
		tokens.forEach((v) => map.set(v.address.toLowerCase(), v))
		return map
	}, [tokens])

	const displayTokensList = useMemo(() => {
		return tokens
			.filter((v) => !getEnvConfigValue('DISABLED_TOKENS').includes(v.symbol))
			.filter((v) => {
				if (searchTerm) {
					const lowercasedSearchTerm = searchTerm.toLowerCase()
					return (
						v.address.toLowerCase().includes(lowercasedSearchTerm) ||
						v.symbol.toLowerCase().includes(lowercasedSearchTerm)
					)
				}
				return true
			})
			.map((v) => ({ userBalance: userBalanceMap.get(v.address) ?? '0', ...v }))
			.sort((a, b) => Number(b.userBalance) - Number(a.userBalance))
	}, [searchTerm, tokens, userBalanceMap])

	const { customToken, isSearchingTokens } = useGetTokenInfo(
		searchTerm,
		!!(searchTerm && displayTokensList.length === 0)
	)

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value)
	}

	const selectToken = (token: Token) => {
		if (!modalOpened) {
			return
		}

		const isInput = modalOpened === 'input'

		const compareToken = isInput ? outputToken : inputToken
		if (token.address === compareToken.address) return
		isInput ? setInputToken(token) : setOutputToken(token)

		closeModal()
	}

	const featureTokenList = useMemo(() => {
		return getEnvConfigValue('FEATURED_TOKENS')
			.map((symbol) => tokens.find((token) => symbol === token.symbol) as Token)
			.filter((token) => !!token)
	}, [tokens])

	const handleTokenImport = (token: Token) => {
		setCustomTokens(token)
		selectToken(token)
		setSearchTerm('')
	}

	const showInputTokenModal = () => {
		setModalOpened('input')
	}

	const showOutputTokenModal = () => {
		setModalOpened('output')
	}

	const closeModal = () => {
		setModalOpened(undefined)
		setSearchTerm('')
	}

	const flipTokens = () => {
		const temp = inputToken
		setInputToken(outputToken)
		setOutputToken(temp)
	}

	return (
		<SelectedTokensContext.Provider
			value={{
				inputToken,
				outputToken,
				tokensMap,
				showInputTokenModal,
				showOutputTokenModal,
				flipTokens
			}}
		>
			{children}
			<div
				className={clsx(
					'modal-custom modal',
					!!modalOpened ? 'modal-open' : 'modal-close'
				)}
			>
				<div
					className="modal-box flex max-h-[100vh] flex-col gap-2 bg-success py-4 px-0 text-primary"
					style={{ maxWidth: 550 }}
				>
					<header className="flex-shrink-1 flex-auto flex-grow-0">
						<div className="flex w-full flex-row items-center justify-between px-5 md:px-10">
							<h3 className="text-md font-bold uppercase 2xl:text-lg ">
								Select a token
							</h3>
							<div className="modal-action mt-0">
								<button
									onClick={closeModal}
									className="btn-xl btn-circle btn w-full justify-end border-transparent bg-transparent text-right text-xl font-extrabold text-primary hover:border-transparent hover:bg-transparent"
								>
									<RxCross2
										size="32px"
										onClick={() => {
											setSearchTerm('')
										}}
									/>
								</button>
							</div>
						</div>
						<div className="px-5 md:px-10">
							<input
								type="search"
								value={searchTerm}
								placeholder="Search name or paste address"
								onChange={handleSearchChange}
								className="focus:shadow-outline h-12 w-full appearance-none rounded-3xl border bg-warning py-2 px-8 text-sm font-[300] leading-tight text-gray-700 placeholder-current shadow  focus:outline-none"
							/>
						</div>
						<FeaturedTokens
							tokenList={featureTokenList}
							handleToken={selectToken}
						/>
					</header>
					<div className="px-5 md:px-10">
						<div className="flex-grow-1 scrollbar mt-6 mb-2 flex max-h-80 flex-auto flex-col overflow-y-auto rounded-3xl bg-warning">
							{displayTokensList.length ? (
								displayTokensList.map((v) => (
									<TokenRow
										key={v.address}
										token={v}
										handleToken={selectToken}
									/>
								))
							) : isSearchingTokens ? (
								<div className="py-2 px-5 text-center md:px-10">
									Searching...
								</div>
							) : customToken ? (
								<TokenImport token={customToken} onImport={handleTokenImport} />
							) : (
								<div className="py-2 px-5 text-center md:px-10">
									<p>No tokens found</p>
									<button
										onClick={() => {
											setSearchTerm('')
										}}
										className="move-up mt-5 rounded-full border-2 border-solid border-accent px-5 py-2 text-sm font-[300]"
									>
										clear the search
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</SelectedTokensContext.Provider>
	)
}

const useSelectedTokensModal = () => {
	const {
		inputToken,
		outputToken,
		tokensMap,
		showOutputTokenModal,
		showInputTokenModal,
		flipTokens
	} = useContext(SelectedTokensContext)
	return {
		inputToken,
		outputToken,
		tokensMap,
		showOutputTokenModal,
		showInputTokenModal,
		flipTokens
	}
}

export default useSelectedTokensModal
